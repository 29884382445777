// source: pbtypes/vehicle/complimentary_actions/complimentary_actions.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_uuid_util_uuid_pb = require('../../../pbtypes/gen/uuid_util/uuid_pb.js');
goog.object.extend(proto, pbtypes_gen_uuid_util_uuid_pb);
var pbtypes_vehicle_complimentary_actions_trigger_args_pb = require('../../../pbtypes/vehicle/complimentary_actions/trigger_args_pb.js');
goog.object.extend(proto, pbtypes_vehicle_complimentary_actions_trigger_args_pb);
var pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_channel_triggered_dummy_channel_triggered_pb = require('../../../pbtypes/vehicle/complimentary_actions/complimentary_actions_impls/dummy_channel_triggered/dummy_channel_triggered_pb.js');
goog.object.extend(proto, pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_channel_triggered_dummy_channel_triggered_pb);
var pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_timer_triggered_dummy_timer_triggered_pb = require('../../../pbtypes/vehicle/complimentary_actions/complimentary_actions_impls/dummy_timer_triggered/dummy_timer_triggered_pb.js');
goog.object.extend(proto, pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_timer_triggered_dummy_timer_triggered_pb);
var pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_send_mission_control_send_mission_control_pb = require('../../../pbtypes/vehicle/complimentary_actions/complimentary_actions_impls/send_mission_control/send_mission_control_pb.js');
goog.object.extend(proto, pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_send_mission_control_send_mission_control_pb);
var pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spawn_complimentary_actions_on_hook_spawn_complimentary_actions_on_hook_pb = require('../../../pbtypes/vehicle/complimentary_actions/complimentary_actions_impls/spawn_complimentary_actions_on_hook/spawn_complimentary_actions_on_hook_pb.js');
goog.object.extend(proto, pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spawn_complimentary_actions_on_hook_spawn_complimentary_actions_on_hook_pb);
var pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spoof_photo_response_spoof_photo_response_pb = require('../../../pbtypes/vehicle/complimentary_actions/complimentary_actions_impls/spoof_photo_response/spoof_photo_response_pb.js');
goog.object.extend(proto, pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spoof_photo_response_spoof_photo_response_pb);
var pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_publish_executive_look_at_command_publish_executive_look_at_command_pb = require('../../../pbtypes/vehicle/complimentary_actions/complimentary_actions_impls/publish_executive_look_at_command/publish_executive_look_at_command_pb.js');
goog.object.extend(proto, pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_publish_executive_look_at_command_publish_executive_look_at_command_pb);
goog.exportSymbol('proto.complimentary_actions.ComplimentaryAction', null, global);
goog.exportSymbol('proto.complimentary_actions.ComplimentaryActionArgs', null, global);
goog.exportSymbol('proto.complimentary_actions.ComplimentaryActionArgs.ArgsCase', null, global);
goog.exportSymbol('proto.complimentary_actions.ComplimentaryActionEvent', null, global);
goog.exportSymbol('proto.complimentary_actions.ComplimentaryActionEvent.EventType', null, global);
goog.exportSymbol('proto.complimentary_actions.RegisterComplimentaryActions', null, global);
goog.exportSymbol('proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.complimentary_actions.ComplimentaryActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.complimentary_actions.ComplimentaryActionArgs.oneofGroups_);
};
goog.inherits(proto.complimentary_actions.ComplimentaryActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.complimentary_actions.ComplimentaryActionArgs.displayName = 'proto.complimentary_actions.ComplimentaryActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.complimentary_actions.ComplimentaryAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.complimentary_actions.ComplimentaryAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.complimentary_actions.ComplimentaryAction.displayName = 'proto.complimentary_actions.ComplimentaryAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.complimentary_actions.RegisterComplimentaryActions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.complimentary_actions.RegisterComplimentaryActions.repeatedFields_, null);
};
goog.inherits(proto.complimentary_actions.RegisterComplimentaryActions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.complimentary_actions.RegisterComplimentaryActions.displayName = 'proto.complimentary_actions.RegisterComplimentaryActions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.complimentary_actions.ComplimentaryActionEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.complimentary_actions.ComplimentaryActionEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.complimentary_actions.ComplimentaryActionEvent.displayName = 'proto.complimentary_actions.ComplimentaryActionEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.repeatedFields_, null);
};
goog.inherits(proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.displayName = 'proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.complimentary_actions.ComplimentaryActionArgs.oneofGroups_ = [[2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.complimentary_actions.ComplimentaryActionArgs.ArgsCase = {
  ARGS_NOT_SET: 0,
  DUMMY_TIMER_TRIGGERED: 2,
  DUMMY_CHANNEL_TRIGGERED: 3,
  SEND_MISSION_CONTROL: 4,
  SPAWN_COMPLIMENTARY_ACTIONS_ON_HOOK: 5,
  SPOOF_PHOTO_RESPONSE: 6,
  PUBLISH_EXECUTIVE_LOOK_AT_COMMAND: 7
};

/**
 * @return {proto.complimentary_actions.ComplimentaryActionArgs.ArgsCase}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.getArgsCase = function() {
  return /** @type {proto.complimentary_actions.ComplimentaryActionArgs.ArgsCase} */(jspb.Message.computeOneofCase(this, proto.complimentary_actions.ComplimentaryActionArgs.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.complimentary_actions.ComplimentaryActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.complimentary_actions.ComplimentaryActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.ComplimentaryActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
dummyTimerTriggered: (f = msg.getDummyTimerTriggered()) && pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_timer_triggered_dummy_timer_triggered_pb.DummyTimerTriggeredActionArgs.toObject(includeInstance, f),
dummyChannelTriggered: (f = msg.getDummyChannelTriggered()) && pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_channel_triggered_dummy_channel_triggered_pb.DummyChannelTriggeredActionArgs.toObject(includeInstance, f),
sendMissionControl: (f = msg.getSendMissionControl()) && pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_send_mission_control_send_mission_control_pb.SendMissionControlActionArgs.toObject(includeInstance, f),
spawnComplimentaryActionsOnHook: (f = msg.getSpawnComplimentaryActionsOnHook()) && proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.toObject(includeInstance, f),
spoofPhotoResponse: (f = msg.getSpoofPhotoResponse()) && pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spoof_photo_response_spoof_photo_response_pb.SpoofPhotoResponseActionArgs.toObject(includeInstance, f),
publishExecutiveLookAtCommand: (f = msg.getPublishExecutiveLookAtCommand()) && pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_publish_executive_look_at_command_publish_executive_look_at_command_pb.PublishExecutiveLookAtCommandActionArgs.toObject(includeInstance, f),
requireCalledAtMostOnce: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs}
 */
proto.complimentary_actions.ComplimentaryActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.complimentary_actions.ComplimentaryActionArgs;
  return proto.complimentary_actions.ComplimentaryActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.complimentary_actions.ComplimentaryActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs}
 */
proto.complimentary_actions.ComplimentaryActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_timer_triggered_dummy_timer_triggered_pb.DummyTimerTriggeredActionArgs;
      reader.readMessage(value,pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_timer_triggered_dummy_timer_triggered_pb.DummyTimerTriggeredActionArgs.deserializeBinaryFromReader);
      msg.setDummyTimerTriggered(value);
      break;
    case 3:
      var value = new pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_channel_triggered_dummy_channel_triggered_pb.DummyChannelTriggeredActionArgs;
      reader.readMessage(value,pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_channel_triggered_dummy_channel_triggered_pb.DummyChannelTriggeredActionArgs.deserializeBinaryFromReader);
      msg.setDummyChannelTriggered(value);
      break;
    case 4:
      var value = new pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_send_mission_control_send_mission_control_pb.SendMissionControlActionArgs;
      reader.readMessage(value,pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_send_mission_control_send_mission_control_pb.SendMissionControlActionArgs.deserializeBinaryFromReader);
      msg.setSendMissionControl(value);
      break;
    case 5:
      var value = new proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs;
      reader.readMessage(value,proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.deserializeBinaryFromReader);
      msg.setSpawnComplimentaryActionsOnHook(value);
      break;
    case 6:
      var value = new pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spoof_photo_response_spoof_photo_response_pb.SpoofPhotoResponseActionArgs;
      reader.readMessage(value,pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spoof_photo_response_spoof_photo_response_pb.SpoofPhotoResponseActionArgs.deserializeBinaryFromReader);
      msg.setSpoofPhotoResponse(value);
      break;
    case 7:
      var value = new pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_publish_executive_look_at_command_publish_executive_look_at_command_pb.PublishExecutiveLookAtCommandActionArgs;
      reader.readMessage(value,pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_publish_executive_look_at_command_publish_executive_look_at_command_pb.PublishExecutiveLookAtCommandActionArgs.deserializeBinaryFromReader);
      msg.setPublishExecutiveLookAtCommand(value);
      break;
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequireCalledAtMostOnce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.complimentary_actions.ComplimentaryActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.complimentary_actions.ComplimentaryActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.ComplimentaryActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDummyTimerTriggered();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_timer_triggered_dummy_timer_triggered_pb.DummyTimerTriggeredActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getDummyChannelTriggered();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_channel_triggered_dummy_channel_triggered_pb.DummyChannelTriggeredActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSendMissionControl();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_send_mission_control_send_mission_control_pb.SendMissionControlActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSpawnComplimentaryActionsOnHook();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSpoofPhotoResponse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spoof_photo_response_spoof_photo_response_pb.SpoofPhotoResponseActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getPublishExecutiveLookAtCommand();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_publish_executive_look_at_command_publish_executive_look_at_command_pb.PublishExecutiveLookAtCommandActionArgs.serializeBinaryToWriter
    );
  }
  f = message.getRequireCalledAtMostOnce();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional DummyTimerTriggeredActionArgs dummy_timer_triggered = 2;
 * @return {?proto.complimentary_actions.DummyTimerTriggeredActionArgs}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.getDummyTimerTriggered = function() {
  return /** @type{?proto.complimentary_actions.DummyTimerTriggeredActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_timer_triggered_dummy_timer_triggered_pb.DummyTimerTriggeredActionArgs, 2));
};


/**
 * @param {?proto.complimentary_actions.DummyTimerTriggeredActionArgs|undefined} value
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs} returns this
*/
proto.complimentary_actions.ComplimentaryActionArgs.prototype.setDummyTimerTriggered = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.complimentary_actions.ComplimentaryActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs} returns this
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.clearDummyTimerTriggered = function() {
  return this.setDummyTimerTriggered(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.hasDummyTimerTriggered = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DummyChannelTriggeredActionArgs dummy_channel_triggered = 3;
 * @return {?proto.complimentary_actions.DummyChannelTriggeredActionArgs}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.getDummyChannelTriggered = function() {
  return /** @type{?proto.complimentary_actions.DummyChannelTriggeredActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_dummy_channel_triggered_dummy_channel_triggered_pb.DummyChannelTriggeredActionArgs, 3));
};


/**
 * @param {?proto.complimentary_actions.DummyChannelTriggeredActionArgs|undefined} value
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs} returns this
*/
proto.complimentary_actions.ComplimentaryActionArgs.prototype.setDummyChannelTriggered = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.complimentary_actions.ComplimentaryActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs} returns this
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.clearDummyChannelTriggered = function() {
  return this.setDummyChannelTriggered(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.hasDummyChannelTriggered = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SendMissionControlActionArgs send_mission_control = 4;
 * @return {?proto.complimentary_actions.SendMissionControlActionArgs}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.getSendMissionControl = function() {
  return /** @type{?proto.complimentary_actions.SendMissionControlActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_send_mission_control_send_mission_control_pb.SendMissionControlActionArgs, 4));
};


/**
 * @param {?proto.complimentary_actions.SendMissionControlActionArgs|undefined} value
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs} returns this
*/
proto.complimentary_actions.ComplimentaryActionArgs.prototype.setSendMissionControl = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.complimentary_actions.ComplimentaryActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs} returns this
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.clearSendMissionControl = function() {
  return this.setSendMissionControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.hasSendMissionControl = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SpawnComplimentaryActionsOnHookActionArgs spawn_complimentary_actions_on_hook = 5;
 * @return {?proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.getSpawnComplimentaryActionsOnHook = function() {
  return /** @type{?proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs} */ (
    jspb.Message.getWrapperField(this, proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs, 5));
};


/**
 * @param {?proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs|undefined} value
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs} returns this
*/
proto.complimentary_actions.ComplimentaryActionArgs.prototype.setSpawnComplimentaryActionsOnHook = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.complimentary_actions.ComplimentaryActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs} returns this
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.clearSpawnComplimentaryActionsOnHook = function() {
  return this.setSpawnComplimentaryActionsOnHook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.hasSpawnComplimentaryActionsOnHook = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SpoofPhotoResponseActionArgs spoof_photo_response = 6;
 * @return {?proto.complimentary_actions.SpoofPhotoResponseActionArgs}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.getSpoofPhotoResponse = function() {
  return /** @type{?proto.complimentary_actions.SpoofPhotoResponseActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spoof_photo_response_spoof_photo_response_pb.SpoofPhotoResponseActionArgs, 6));
};


/**
 * @param {?proto.complimentary_actions.SpoofPhotoResponseActionArgs|undefined} value
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs} returns this
*/
proto.complimentary_actions.ComplimentaryActionArgs.prototype.setSpoofPhotoResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.complimentary_actions.ComplimentaryActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs} returns this
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.clearSpoofPhotoResponse = function() {
  return this.setSpoofPhotoResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.hasSpoofPhotoResponse = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PublishExecutiveLookAtCommandActionArgs publish_executive_look_at_command = 7;
 * @return {?proto.complimentary_actions.PublishExecutiveLookAtCommandActionArgs}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.getPublishExecutiveLookAtCommand = function() {
  return /** @type{?proto.complimentary_actions.PublishExecutiveLookAtCommandActionArgs} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_publish_executive_look_at_command_publish_executive_look_at_command_pb.PublishExecutiveLookAtCommandActionArgs, 7));
};


/**
 * @param {?proto.complimentary_actions.PublishExecutiveLookAtCommandActionArgs|undefined} value
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs} returns this
*/
proto.complimentary_actions.ComplimentaryActionArgs.prototype.setPublishExecutiveLookAtCommand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.complimentary_actions.ComplimentaryActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs} returns this
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.clearPublishExecutiveLookAtCommand = function() {
  return this.setPublishExecutiveLookAtCommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.hasPublishExecutiveLookAtCommand = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool require_called_at_most_once = 1;
 * @return {boolean}
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.getRequireCalledAtMostOnce = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.complimentary_actions.ComplimentaryActionArgs} returns this
 */
proto.complimentary_actions.ComplimentaryActionArgs.prototype.setRequireCalledAtMostOnce = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.complimentary_actions.ComplimentaryAction.prototype.toObject = function(opt_includeInstance) {
  return proto.complimentary_actions.ComplimentaryAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.complimentary_actions.ComplimentaryAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.ComplimentaryAction.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: (f = msg.getUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
actionArgs: (f = msg.getActionArgs()) && proto.complimentary_actions.ComplimentaryActionArgs.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.complimentary_actions.ComplimentaryAction}
 */
proto.complimentary_actions.ComplimentaryAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.complimentary_actions.ComplimentaryAction;
  return proto.complimentary_actions.ComplimentaryAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.complimentary_actions.ComplimentaryAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.complimentary_actions.ComplimentaryAction}
 */
proto.complimentary_actions.ComplimentaryAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setUuid(value);
      break;
    case 2:
      var value = new proto.complimentary_actions.ComplimentaryActionArgs;
      reader.readMessage(value,proto.complimentary_actions.ComplimentaryActionArgs.deserializeBinaryFromReader);
      msg.setActionArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.complimentary_actions.ComplimentaryAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.complimentary_actions.ComplimentaryAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.complimentary_actions.ComplimentaryAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.ComplimentaryAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getActionArgs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.complimentary_actions.ComplimentaryActionArgs.serializeBinaryToWriter
    );
  }
};


/**
 * optional uuid_util.Uuid uuid = 1;
 * @return {?proto.uuid_util.Uuid}
 */
proto.complimentary_actions.ComplimentaryAction.prototype.getUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 1));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.complimentary_actions.ComplimentaryAction} returns this
*/
proto.complimentary_actions.ComplimentaryAction.prototype.setUuid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.ComplimentaryAction} returns this
 */
proto.complimentary_actions.ComplimentaryAction.prototype.clearUuid = function() {
  return this.setUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.ComplimentaryAction.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ComplimentaryActionArgs action_args = 2;
 * @return {?proto.complimentary_actions.ComplimentaryActionArgs}
 */
proto.complimentary_actions.ComplimentaryAction.prototype.getActionArgs = function() {
  return /** @type{?proto.complimentary_actions.ComplimentaryActionArgs} */ (
    jspb.Message.getWrapperField(this, proto.complimentary_actions.ComplimentaryActionArgs, 2));
};


/**
 * @param {?proto.complimentary_actions.ComplimentaryActionArgs|undefined} value
 * @return {!proto.complimentary_actions.ComplimentaryAction} returns this
*/
proto.complimentary_actions.ComplimentaryAction.prototype.setActionArgs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.ComplimentaryAction} returns this
 */
proto.complimentary_actions.ComplimentaryAction.prototype.clearActionArgs = function() {
  return this.setActionArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.ComplimentaryAction.prototype.hasActionArgs = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.complimentary_actions.RegisterComplimentaryActions.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.complimentary_actions.RegisterComplimentaryActions.prototype.toObject = function(opt_includeInstance) {
  return proto.complimentary_actions.RegisterComplimentaryActions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.complimentary_actions.RegisterComplimentaryActions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.RegisterComplimentaryActions.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
actionsToRegisterList: jspb.Message.toObjectList(msg.getActionsToRegisterList(),
    proto.complimentary_actions.ComplimentaryAction.toObject, includeInstance),
uclock: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.complimentary_actions.RegisterComplimentaryActions}
 */
proto.complimentary_actions.RegisterComplimentaryActions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.complimentary_actions.RegisterComplimentaryActions;
  return proto.complimentary_actions.RegisterComplimentaryActions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.complimentary_actions.RegisterComplimentaryActions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.complimentary_actions.RegisterComplimentaryActions}
 */
proto.complimentary_actions.RegisterComplimentaryActions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new proto.complimentary_actions.ComplimentaryAction;
      reader.readMessage(value,proto.complimentary_actions.ComplimentaryAction.deserializeBinaryFromReader);
      msg.addActionsToRegister(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUclock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.complimentary_actions.RegisterComplimentaryActions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.complimentary_actions.RegisterComplimentaryActions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.complimentary_actions.RegisterComplimentaryActions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.RegisterComplimentaryActions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getActionsToRegisterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.complimentary_actions.ComplimentaryAction.serializeBinaryToWriter
    );
  }
  f = message.getUclock();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.complimentary_actions.RegisterComplimentaryActions.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.complimentary_actions.RegisterComplimentaryActions} returns this
 */
proto.complimentary_actions.RegisterComplimentaryActions.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated ComplimentaryAction actions_to_register = 2;
 * @return {!Array<!proto.complimentary_actions.ComplimentaryAction>}
 */
proto.complimentary_actions.RegisterComplimentaryActions.prototype.getActionsToRegisterList = function() {
  return /** @type{!Array<!proto.complimentary_actions.ComplimentaryAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.complimentary_actions.ComplimentaryAction, 2));
};


/**
 * @param {!Array<!proto.complimentary_actions.ComplimentaryAction>} value
 * @return {!proto.complimentary_actions.RegisterComplimentaryActions} returns this
*/
proto.complimentary_actions.RegisterComplimentaryActions.prototype.setActionsToRegisterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.complimentary_actions.ComplimentaryAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.complimentary_actions.ComplimentaryAction}
 */
proto.complimentary_actions.RegisterComplimentaryActions.prototype.addActionsToRegister = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.complimentary_actions.ComplimentaryAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.complimentary_actions.RegisterComplimentaryActions} returns this
 */
proto.complimentary_actions.RegisterComplimentaryActions.prototype.clearActionsToRegisterList = function() {
  return this.setActionsToRegisterList([]);
};


/**
 * optional int64 uclock = 3;
 * @return {number}
 */
proto.complimentary_actions.RegisterComplimentaryActions.prototype.getUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.complimentary_actions.RegisterComplimentaryActions} returns this
 */
proto.complimentary_actions.RegisterComplimentaryActions.prototype.setUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.complimentary_actions.ComplimentaryActionEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.complimentary_actions.ComplimentaryActionEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.complimentary_actions.ComplimentaryActionEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.ComplimentaryActionEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
uuid: (f = msg.getUuid()) && pbtypes_gen_uuid_util_uuid_pb.Uuid.toObject(includeInstance, f),
event: jspb.Message.getFieldWithDefault(msg, 3, 0),
name: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.complimentary_actions.ComplimentaryActionEvent}
 */
proto.complimentary_actions.ComplimentaryActionEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.complimentary_actions.ComplimentaryActionEvent;
  return proto.complimentary_actions.ComplimentaryActionEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.complimentary_actions.ComplimentaryActionEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.complimentary_actions.ComplimentaryActionEvent}
 */
proto.complimentary_actions.ComplimentaryActionEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_uuid_util_uuid_pb.Uuid;
      reader.readMessage(value,pbtypes_gen_uuid_util_uuid_pb.Uuid.deserializeBinaryFromReader);
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {!proto.complimentary_actions.ComplimentaryActionEvent.EventType} */ (reader.readEnum());
      msg.setEvent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.complimentary_actions.ComplimentaryActionEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.complimentary_actions.ComplimentaryActionEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.complimentary_actions.ComplimentaryActionEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.ComplimentaryActionEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_uuid_util_uuid_pb.Uuid.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.complimentary_actions.ComplimentaryActionEvent.EventType = {
  INVALID: 0,
  REGISTERED: 1,
  FIRST_TRIGGER: 2,
  DONE: 3
};

/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.complimentary_actions.ComplimentaryActionEvent.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.complimentary_actions.ComplimentaryActionEvent} returns this
 */
proto.complimentary_actions.ComplimentaryActionEvent.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uuid_util.Uuid uuid = 2;
 * @return {?proto.uuid_util.Uuid}
 */
proto.complimentary_actions.ComplimentaryActionEvent.prototype.getUuid = function() {
  return /** @type{?proto.uuid_util.Uuid} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_uuid_util_uuid_pb.Uuid, 2));
};


/**
 * @param {?proto.uuid_util.Uuid|undefined} value
 * @return {!proto.complimentary_actions.ComplimentaryActionEvent} returns this
*/
proto.complimentary_actions.ComplimentaryActionEvent.prototype.setUuid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.ComplimentaryActionEvent} returns this
 */
proto.complimentary_actions.ComplimentaryActionEvent.prototype.clearUuid = function() {
  return this.setUuid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.ComplimentaryActionEvent.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EventType event = 3;
 * @return {!proto.complimentary_actions.ComplimentaryActionEvent.EventType}
 */
proto.complimentary_actions.ComplimentaryActionEvent.prototype.getEvent = function() {
  return /** @type {!proto.complimentary_actions.ComplimentaryActionEvent.EventType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.complimentary_actions.ComplimentaryActionEvent.EventType} value
 * @return {!proto.complimentary_actions.ComplimentaryActionEvent} returns this
 */
proto.complimentary_actions.ComplimentaryActionEvent.prototype.setEvent = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.complimentary_actions.ComplimentaryActionEvent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.complimentary_actions.ComplimentaryActionEvent} returns this
 */
proto.complimentary_actions.ComplimentaryActionEvent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
trigger: (f = msg.getTrigger()) && pbtypes_vehicle_complimentary_actions_trigger_args_pb.TriggerArgs.ChannelCallback.toObject(includeInstance, f),
hook: (f = msg.getHook()) && pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spawn_complimentary_actions_on_hook_spawn_complimentary_actions_on_hook_pb.SpawnComplimentaryActionHook.toObject(includeInstance, f),
childActionsList: jspb.Message.toObjectList(msg.getChildActionsList(),
    proto.complimentary_actions.ComplimentaryAction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs}
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs;
  return proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs}
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_complimentary_actions_trigger_args_pb.TriggerArgs.ChannelCallback;
      reader.readMessage(value,pbtypes_vehicle_complimentary_actions_trigger_args_pb.TriggerArgs.ChannelCallback.deserializeBinaryFromReader);
      msg.setTrigger(value);
      break;
    case 2:
      var value = new pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spawn_complimentary_actions_on_hook_spawn_complimentary_actions_on_hook_pb.SpawnComplimentaryActionHook;
      reader.readMessage(value,pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spawn_complimentary_actions_on_hook_spawn_complimentary_actions_on_hook_pb.SpawnComplimentaryActionHook.deserializeBinaryFromReader);
      msg.setHook(value);
      break;
    case 3:
      var value = new proto.complimentary_actions.ComplimentaryAction;
      reader.readMessage(value,proto.complimentary_actions.ComplimentaryAction.deserializeBinaryFromReader);
      msg.addChildActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrigger();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_complimentary_actions_trigger_args_pb.TriggerArgs.ChannelCallback.serializeBinaryToWriter
    );
  }
  f = message.getHook();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spawn_complimentary_actions_on_hook_spawn_complimentary_actions_on_hook_pb.SpawnComplimentaryActionHook.serializeBinaryToWriter
    );
  }
  f = message.getChildActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.complimentary_actions.ComplimentaryAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional TriggerArgs.ChannelCallback trigger = 1;
 * @return {?proto.complimentary_actions.TriggerArgs.ChannelCallback}
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.getTrigger = function() {
  return /** @type{?proto.complimentary_actions.TriggerArgs.ChannelCallback} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_complimentary_actions_trigger_args_pb.TriggerArgs.ChannelCallback, 1));
};


/**
 * @param {?proto.complimentary_actions.TriggerArgs.ChannelCallback|undefined} value
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs} returns this
*/
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.setTrigger = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs} returns this
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.clearTrigger = function() {
  return this.setTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.hasTrigger = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SpawnComplimentaryActionHook hook = 2;
 * @return {?proto.complimentary_actions.SpawnComplimentaryActionHook}
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.getHook = function() {
  return /** @type{?proto.complimentary_actions.SpawnComplimentaryActionHook} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_complimentary_actions_complimentary_actions_impls_spawn_complimentary_actions_on_hook_spawn_complimentary_actions_on_hook_pb.SpawnComplimentaryActionHook, 2));
};


/**
 * @param {?proto.complimentary_actions.SpawnComplimentaryActionHook|undefined} value
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs} returns this
*/
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.setHook = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs} returns this
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.clearHook = function() {
  return this.setHook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.hasHook = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ComplimentaryAction child_actions = 3;
 * @return {!Array<!proto.complimentary_actions.ComplimentaryAction>}
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.getChildActionsList = function() {
  return /** @type{!Array<!proto.complimentary_actions.ComplimentaryAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.complimentary_actions.ComplimentaryAction, 3));
};


/**
 * @param {!Array<!proto.complimentary_actions.ComplimentaryAction>} value
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs} returns this
*/
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.setChildActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.complimentary_actions.ComplimentaryAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.complimentary_actions.ComplimentaryAction}
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.addChildActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.complimentary_actions.ComplimentaryAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs} returns this
 */
proto.complimentary_actions.SpawnComplimentaryActionsOnHookActionArgs.prototype.clearChildActionsList = function() {
  return this.setChildActionsList([]);
};


goog.object.extend(exports, proto.complimentary_actions);
